import "../../css/etapeComp.css"
import CarteComp from "../../composants/EtapeForm/EtapeComp/CarteComp"
import ProgressionBar from "../../composants/EtapeForm/ProgressionBar"
import flecheEtape from "../../images/design/flecheEtape.svg"
import { Link } from "react-router-dom";
import { useState } from "react";

export default function PageComplementaire(props) {
  const [data, setData] = useState(props.data)
  function NextPage() {
    window.scrollTo(0, 0);
    props.submitData();

  }
  function handleEtape(step) {
    if (step < 2) {
      props.handleCallback(step);
    }
  }
  function backPage() {
    props.handleCallback(props.etape - 1);
  }
  return <div className="page-comp">
    <header>
      <div className="header-text" >
        <img src={flecheEtape} alt="fleche de design" />
        <h3 onClick={handleEtape}><p className="link" onClick={backPage}> Retour à l'étape {props.etape}</p> </h3>
      </div>
      <div>
        <ProgressionBar status={["termine", "termine", "cours", "venir"]} handleCallback={handleEtape} />
      </div>
    </header>
    <div className="titre">
      <h1>
        Dites-nous en un peu plus sur vous et votre formation
      </h1>
      <h2>
        Cliquez sur l’icône “+” pour renseigner vos informations . Toutes les informations de cette page sont facultatives, ce n’est donc pas un souci si vous ne pouvez pas répondre à tout. Cependant, gardez en tête que la qualité du résultat final dépendra du nombre d’informations que vous nous confierez.
      </h2>
    </div>

    <form>
      <CarteComp lengthText={4000} IInfos="" index="1" data={data.doc1} description="Le programme doit répertorier tous ce que les participants vont être amenés à voir. Celui-ci doit être structuré." title="Programme de formation" srcVideo="https://www.youtube.com/embed/hOF52ICN_Qs?si=I_deqTT7RJxhcPkz" getDocs={props.getDocs1} />
      <CarteComp lengthText={4000} IInfos="" index="2" data={data.doc2} description="Ces quiz servent à évaluer le niveau des participants avant et après la formation. Ils mesurent l’impact de la formation." title="Quiz pré et post formation" srcVideo="https://www.youtube.com/embed/2kuDF0e9-4E?si=0-dveK3i3unUF6pd" getDocs={props.getDocs2} />
      <CarteComp lengthText={4000} IInfos="" index="3" data={data.doc3} description="Les objectifs de formation définissent les résultats attendus pour améliorer les compétences des participants." title="Objectifs de formation" srcVideo="https://www.youtube.com/embed/PN-Jz8O6qSY?si=pdZGfdnQB6u9HIY9" getDocs={props.getDocs3} />
      <CarteComp lengthText={4000} IInfos="" index="4" data={data.doc4} description="Le support de formation se présente souvent sous la forme de diapositives pour illustrer le cours." title="Support de formation" srcVideo="https://www.youtube.com/embed/5SG_uARH-7w?si=iaIpDGzGMQ7VS7QA" getDocs={props.getDocs4} />
    </form>

    <button className="next-button" onClick={NextPage}> Suivant </button>
  </div>;
}