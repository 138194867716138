import React, { useRef, useEffect, useState } from "react";
import imgI from "../../images/design/i.svg";

export default function ButtonInfo(props) {
  const infoContainerRef = useRef(null);
  const [display, setDisplay] = useState(true);
  const [infoContainerWidth, setInfoContainerWidth] = useState("fit-content");
  const [infoContainerHeight, setInfoContainerHeight] = useState("-100%");

  function displayInfo(event) {
    event.preventDefault();
    setDisplay(!display);
  }

  useEffect(() => {
    // Fonction de gestion de l'événement click en dehors de l'élément d'information
    function handleClickOutside(event) {
      if (infoContainerRef.current && !infoContainerRef.current.contains(event.target)) {
        setDisplay(true);
      }
    }

    // Ajouter l'écouteur d'événements au chargement du composant
    document.addEventListener("click", handleClickOutside);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Le tableau vide en tant que deuxième argument assure que l'effet s'exécute une seule fois lors du montage du composant

  return (
    <div className="container-button-info" ref={infoContainerRef}>
      <button className="boutton-info" onClick={displayInfo}>
        <img src={imgI} alt="Info Button" />
      </button>
      {!display && (
        <div className="info-container" >
          <p className="container-text">{props.infos}</p>
        </div>
      )}
    </div>
  );
}
