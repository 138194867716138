import { Axios } from "../config";

export const testConnection = async (data, setMethod) => {
  console.log("email check")
  try {
    const res = await Axios.post('/connexion', data).then(res=>{
      setMethod(res.data);
      console.log(res.data)
    });
  } catch (err) {
    console.error(err);
  }
};


export const postClient = async (data, setMethod) => {
    try {
      const res = await Axios.post('/requete', data).then(res=>{
       console.log("res.data")
       console.log(res.data)
       
       let listMessage= res.data
        //let parts = res.data.split(/\/\*-{50,}\*\//);
        //parts = parts.filter(part => part.trim().length > 0);
        //listMessage.push(parts[0])
        listMessage = listMessage.slice(1,listMessage.length)
      console.log(listMessage)
        
        
        
       
        setMethod(listMessage);
        
      });
    } catch (err) {
      console.error(err);
    }
  };