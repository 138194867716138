import WarningDiv from "../../composants/EtapeForm/PageResultat/WarningDiv"
import ResultatArea from "../../composants/EtapeForm/PageResultat/ResultatArea";
import TableauArea from "../../composants/EtapeForm/PageResultat/TableauArea";
import logoResult1 from "../../images/design/result1.svg"
import logoResult2 from "../../images/design/result2.svg"
import logoResult3 from "../../images/design/result3.svg"
import logoResult4 from "../../images/design/result4.svg"
import playButton from "../../images/whitePlayButton.svg"
import ProgressionBar from "../../composants/EtapeForm/ProgressionBar"
import flecheEtape from "../../images/design/flecheEtape.svg"
import { Link } from "react-router-dom";
import "../../css/etapeResu.css"
import { useState, useEffect } from "react";

import React from "react";
import Lottie from "lottie-react";
import { useLottie } from "lottie-react";
import waitingAnimation from "../../images/design/formabuildertest.json"


export default function PageResultat(props) {
    const [waitingDisplay, setWaitingDisplay] = useState(true);
    const [content, setContent] = useState(props.data);
    const [displayedDocument, setDisplayedDocument] = useState(["Programme de formation", "Quiz pré et post formation", "Objectifs de formation", "Support de formation", "Déroulé Pédagogique"]);


    const options = {
        animationData: waitingAnimation,
        loop: true
    };

    const { View } = useLottie(options);

    const filteredList = displayedDocument.filter((document, index) => {
        return props.documentDisplayed[index];
    });
    let tableHtml;
    let lines;

    useEffect(() => {
        setContent(props.data);
        const listData = props.data // Update the content state when props.text changes

        listData.forEach((item) => {
            if (item !== "chargement de la réponse") {
                setWaitingDisplay(false)
            }
        })



    }, [props.data]);

    function handleEtape(step) {
        if (step < 3) {

            props.handleCallback(step);
        }
    }
    function backPage() {
        props.handleCallback(props.etape - 1);
    }

    function NextPage() {
        setWaitingDisplay(true)
        window.scrollTo(0, 0);
        props.submitData();
    }

    return <div className="page-resultat">
        <header>
            <div className="header-text" >
                <img src={flecheEtape} alt="fleche de design" />
                <h3 onClick={handleEtape}><p className="link" onClick={backPage}> Retour à l'étape {props.etape}</p></h3>
            </div>
            <div>
                <ProgressionBar status={["termine", "termine", "termine", "cours"]} handleCallback={handleEtape} />
            </div>
        </header>
        {waitingDisplay ?
            <div className="container-waiting">
                <Lottie className="lottie" animationData={waitingAnimation} loop={true} />
                <h2>Le résultat approche !</h2>
                <p>{"L’intelligence artificielle est en train de travailler… \nCela peut prendre quelques minutes alors n’hésitez pas à aller vous chercher un café en attendant ☕"}</p>
            </div> :
            <div className="content-resultat">
                <div className="titre">
                    <div className="row-titre">
                        <div className="titre-gauche">
                            <h1>
                                Voici vos documents !
                            </h1>
                            <p>
                                Voici les documents dont vous aviez besoin pour compléter votre dossier. <br></br>
                                Modifier-les à votre guise avant de les copier !
                            </p>
                        </div>
                        <div className="titre-droite">
                            <button onClick={NextPage}>Recommencer</button>
                            <p>Cliquez sur ce bouton si le résultat ne vous convient pas</p>
                        </div>
                    </div>
                    <WarningDiv />
                </div>
                <div className="resultat-area-container">
                    {filteredList.map((doc) => {

                        const index = displayedDocument.indexOf(doc);
                        if (doc !== "Déroulé Pédagogique") {
                            return (

                                <ResultatArea
                                    titre={doc}
                                    text={content[index]}
                                    key={index}
                                />
                            );
                        } else {
                            return (
                                <TableauArea
                                    titre={doc}
                                    text={content[index]}
                                    key={index}
                                />
                            )
                        }
                    })}
                </div>
                <div className="info-docs-resultats">
                    <h2>Comment transmettre ces documents à formagora ?</h2>
                    <div className="logo-resultat-container">
                        <div className="logo-resultat">
                            <div className="item item1">
                                <div className="container-img-result">
                                    <img src={logoResult1} alt="" />
                                </div>
                                <h3>Relire et corriger les résultats</h3>
                            </div>
                            <div className="item item2">
                                <div className="container-img-result">
                                    <img src={logoResult2} alt="" />
                                </div>
                                <h3>Copier le document</h3>
                            </div>
                            <div className="item item3">
                                <div className="container-img-result">
                                    <img src={logoResult3} alt="" />
                                </div>
                                <h3>Se connecter à Mobiléo</h3>
                            </div>
                            <div className="item item4">
                                <div className="container-img-result">
                                    <img src={logoResult4} alt="" />
                                </div>
                                <h3>Coller le document dans le champ correspondant</h3>
                            </div>
                        </div>
                        <Link className="link" to="/tutoriels"><button><img className="play-button-resultat" src={playButton} /><p>Voir nos tutoriels vidéo</p></button> </Link>
                    </div>
                </div></div>
        }
    </div>;
}