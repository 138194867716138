import { Link } from "react-router-dom";
import ListTutoriels from "../composants/pageTutoriels/ListTutoriels";
import flecheEtape from "../images/design/flecheEtape.svg";
import "../css/pageMentions.css";

export default function PagePolitique() {
  return (
    <div className="page-mentions">
      <header>
        <div className="header-text">
          <img src={flecheEtape} alt="fleche de design" />
          <h3>
            <Link className="link" to="/">
              <p> Retour à l'accueil</p>
            </Link>
          </h3>
        </div>
      </header>
      <div className="content-container">
        <div className="texte-configuration">
        <h1>Politique de Confidentialité</h1>
        <br></br>
        <p>Dernière mise à jour : 30 Août 2023</p>
        <br></br>
        <p>FORMAGORA sarl (ci-après dénommée "nous", "notre" ou "l'entreprise") s'engage à protéger la confidentialité et la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles, notamment celles générées à partir de l'utilisation de l'API de ChatGPT 3.5. 
        En utilisant notre site web et nos services, vous acceptez les conditions décrites dans cette politique. 
        </p>
        <br></br>
        </div>
            <h2>Collecte et Utilisation des Données</h2>
            <p>Lorsque vous utilisez notre site web et notre service de génération de documents administratifs basé sur l'API de ChatGPT 3.5, nous pouvons collecter certaines informations personnelles pour améliorer votre expérience et fournir des résultats pertinents. Les informations collectées peuvent inclure, mais ne sont pas limitées à :</p>
            <ul>
                <li>Les informations que vous fournissez volontairement lors de l'utilisation de notre service, telles que les données administratives et les questions posées ;</li>
                <li>Les données techniques telles que le type de navigateur, le système d'exploitation et d'autres informations similaires ;</li>
                <li>Ces données sont utilisées uniquement pour générer les documents souhaités, améliorer notre service et assurer la conformité avec les lois et réglementations en vigueur.</li>
            </ul>
             <br></br>
            <h2>Protection des Données</h2>
            <p>Nous prenons des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre tout accès non autorisé, toute divulgation ou toute utilisation abusive. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est parfaitement sécurisée. Bien que nous nous efforcions de protéger vos informations, nous ne pouvons garantir leur sécurité absolue.</p>
            <br></br>
            <h2>Partage des Données</h2>
            <p>Nous ne partageons pas vos données personnelles générées par l'utilisation de l'API de ChatGPT 3.5 avec des tiers, sauf dans les cas suivants :</p>
            <ul>
                <li>Avec votre consentement explicite ;</li>
                <li>Si requis par la loi ou en réponse à une demande légale officielle ;</li>
                <li>Pour protéger nos droits, notre sécurité ou celle de nos utilisateurs.</li>
            </ul>
            <br></br>
            <h2>Responsabilité envers OpenAI</h2>
            <p>Veuillez noter que nous ne sommes pas responsables des politiques, pratiques de confidentialité ou actions entreprises par OpenAI concernant les données que vous fournissez à travers l’API ChatGPT 3.5. L'utilisation de cette API est régie par les termes et conditions de OpenAI, et nous vous encourageons à consulter la <Link className="linkb" to="https://openai.com/policies/privacy-policy" target="_blank">politique de confidentialitéde OpenAI</Link>  pour comprendre comment ils collectent, utilisent et protègent les données.</p>
            <br></br>
            <h2>Vos Droits</h2>
            <p>Vous avez le droit d'accéder à vos données personnelles, de les corriger, de les supprimer. Vous pouvez également vous opposer au traitement de vos données à des fins de marketing direct. Pour exercer ces droits ou pour toute question concernant notre politique de confidentialité, veuillez nous contacter à l'adresse indiquée ci-dessous.</p>
            <br></br>
            <h2>Contact</h2>
            <p>Pour toute question, réclamation ou demande concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : <Link className="linkb" to="mailto:hello@formagora.fr" target="_blank">hello@formagora.fr</Link></p>
            <br></br>
            <br></br>
        </div>
      </div>
  );
}
