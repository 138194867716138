import { Link } from "react-router-dom";
import ListTutoriels from "../composants/pageTutoriels/ListTutoriels";
import flecheEtape from "../images/design/flecheEtape.svg";
import "../css/pageMentions.css";

export default function PageMentions() {
  return (
    <div className="page-mentions">
      <header>
        <div className="header-text">
          <img src={flecheEtape} alt="fleche de design" />
          <h3>
            <Link className="link" to="/">
              <p> Retour à l'accueil</p>
            </Link>
          </h3>
        </div>
      </header>
      <div className="content-container">
        <div className="texte-configuration">
        <h1>Mentions Légales</h1>
        <br></br>
          <h2>Configuration recommandée</h2>
            <h3>Navigateurs</h3>
            <p>
              Pour une navigation optimale, nous vous conseillons d’utiliser :
            <br></br>
            Mozilla Firefox (PC & Mac) ou Internet Explorer 8 minimum (PC) ou Safari (Mac) ou Chrome
            </p>
            <h3>Configuration écran</h3>
            <p>
              Préférez un écran paramétré en milliers de couleurs, avec une
              résolution minimum de 1024×768 pixels.
            </p>
        </div>
        <div className="mentions-legales">
        <br></br>
          <h2>Crédits</h2>
          <address>
            FORMAGORA sarl<br />
            40 Allée de la Saulaie,<br />
            49800 Trélazé<br />
            02 53 20 68 68
          </address>
          <p>
            FORMAGORA est une société à responsabilité limitée appartenant à
            AC2V sarl (Numéro SIRET (siège) 84012707000026). <br/>
            AC2V est également propriétaire de la marque FORMAGORA®.</p>
            <p>Directeur de la publication : Vincent Faurie, gérant de la SARL formagora. Numéro SIRET (siège) 88474555500026</p>
            <br/>
            <p><b>Conception du site internet :</b> FORMAGORA</p>
            <p><b>Développement du site Internet :</b> FORMAGORA</p>
            <p><b>Hébergement du site internet :</b> O2Switch</p>
            <p><b>Intelligence artificielle utilisée :</b> ChatGPT 3.5 conçu par OpenAI</p>
            <br/>
            <h3>Responsabilité quant à l'utilisation de l'API </h3>
            <p>Les documents administratifs générés à partir de l'API de ChatGPT 3.5 sont élaborés en fonction des informations fournies par l'utilisateur. Il est important de noter que la responsabilité de l'exactitude, de la pertinence et de la conformité de ces documents incombe entièrement à l'utilisateur. Nous ne pouvons garantir ni endosser la validité ou l'exactitude des informations générées par l'API. L'utilisation de l'API se fait aux risques et périls de l'utilisateur, qui doit vérifier soigneusement les informations fournies avant de les utiliser à des fins administratives ou légales.</p>
            <br></br>
            <h3>Protection des données</h3>
            <p>Dans le cas où l'utilisation de l'API implique la collecte, le traitement ou le stockage de données personnelles, nous nous engageons à respecter les lois et réglementations en vigueur en matière de protection des données. Les données personnelles fournies par les utilisateurs seront traitées conformément à notre politique de confidentialité, qui détaille les finalités de la collecte, les droits des utilisateurs et les mesures de sécurité mises en place. Nous précisons également que nous ne sommes pas responsables de la manière dont OpenAI utilise vos données. Pour en savoir plus à ce sujet, veuillez consulter le <Link className="linkb" to="https://openai.com/policies/privacy-policy" target="_blank">site internet d’OpenAI.</Link></p>
            <br></br>
            <h3>Propriété intellectuelle</h3>
            <p>L'intelligence artificielle utilisée pour générer les documents administratifs (ChatGPT 3.5) est développée par OpenAI et est protégée par des droits de propriété intellectuelle, y compris, mais sans s'y limiter, les droits d'auteur et les droits de brevet. Toute utilisation non autorisée de l'API ou des résultats générés peut constituer une violation de ces droits.</p>
            <br></br>
            <p>Pour tout problème de consultation rencontré sur le site merci d’adresser vos réclamations à <Link className="linkb" to="mailto:hello@formagora.fr" target="_blank">hello@formagora.fr</Link></p>
            <br></br>
            <br></br>
        </div>
      </div>
    </div>
  );
}
