
import CartePage from "./CartePage"
import imgCarte1 from "../../images/cartes/card1.webp"
import imgCarte2 from "../../images/cartes/card2.webp"
import imgCarte3 from "../../images/cartes/card3.webp"
export default function ListeCartes(){


   


    return <div className="liste-carte" >
        <CartePage srcImg={imgCarte1} titre="Dites-nous de quel(s) document(s) vous avez besoin" text="Nous adapterons nos questions en fonction de ce que vous nous demandez." />
        <CartePage srcImg={imgCarte2} titre="Parlez-nous de votre formation " text="Vous serez amené à répondre à quelques questions sur votre formation pour que les documents générés soit les plus pertinents possibles." />
        <CartePage srcImg={imgCarte3} titre="Ajustez les documents à votre guise, puis envoyer-les nous !" text="Une fois vos documents générés, vous avez la possibilité de les modifier ou bien de recommencer pour avoir quelque chose qui vous corresponds à 100%." />
    </div>
}