import logo from "../../images/design/logoForma2.png"
import { useState, useEffect } from "react";
import "../../css/navbar.css"
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
export default function NavBar() {
  const { pathname } = useLocation();
const [state, setState] = useState(pressedButton());


function pressedButton(){
  if(pathname=="/EtapeForm"){
    return ["pressed-link", "", ""]
   }else if(pathname=="/"){
    return ["", "pressed-link", ""]
   }else if(pathname=="/Tutoriels"){
    return ["", "", "pressed-link"]
   }else return ["", "", ""]
}

useEffect(() => {

  if(pathname=="/EtapeForm"){
    setState(["pressed-link", "unpressed", "unpressed"])
   }else if(pathname=="/"){
    setState(["unpressed", "pressed-link", "unpressed"])
   }else if(pathname=="/Tutoriels"){
    setState(["unpressed", "unpressed", "pressed-link"])
   }else {
    setState(["unpressed", "unpressed", "unpressed"])
   }

}, [pathname]);


  return (
    <nav>
      <div className="largeur">
      <div>
      <Link className="logo" to="/">
        <img src={logo}/>
        </Link>
      </div>
      <div className="liens-navbar">
        <ul >
        <Link className="linke"  to="/EtapeForm">
            <li className={state[0]}>Générer mes documents</li>
            </Link>
            <Link className="linke" to="/#solution" >
            <li className={state[1]}>Notre solution</li>
            </Link>
        <Link className="linke"  to="/Tutoriels">
            <li className={state[2]}>Nos tutoriels</li>
        </Link>
        </ul>
      </div>
      </div>
    </nav>
  );
}