import warning from "../../../images/design/warning.png"
export default function WarningDiv() {

    return <div className="warning-container">
        <img src={warning} alt="" />
        <div className="text-warning">
            <h3>Vous devez impérativement relire et corriger les documents</h3>
            <p>Ces documents sont générés avec une intelligence artificielle. Bien que celle-ci soit performante, elle ne remplacera pas votre expertise de formateur. Il est donc important que vous vous appropriez les documents et que vous les adaptiez à vos besoins avant de les envoyer à formagora.</p>
        </div>
    </div> ;
  }


  