import { useState } from "react";
import playButton from "../../../images/playButton.svg";
import croix from "../../../images/croix.svg";
import ButtonInfo from "../ButtonInfo";

export default function CarteComp(props) {
  
  const [display, setDisplay] = useState(true);
  const [displayVideo, setDisplayVideo] = useState(true);
  const [text, setText] = useState(props.data);
  const [check, setCheck] = useState(firstCheck);
  const [textErrorCss, setTitreErrorCss]= useState("not-triggered");
  function inputText(e) {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
  
    if (text.length < props.lengthText || e.target.value.length < text.length ) {
      setText(lowerCase);
      setTitreErrorCss("not-triggered")
    } else {
      setTitreErrorCss("trigger-error");
    }
  }
  

  function handleDisplay(e) {
    e.preventDefault()
    setTitreErrorCss("not-triggered")
    setDisplay(!display);
  }

  function handleDisplayVideo(e) {
    e.stopPropagation();
          e.preventDefault();
    setDisplayVideo(!displayVideo);
    
  }
  function firstCheck(){
    if(text!==""){
      return true
      
    }else{
      return false
    }
  }

  function validateButton(e){
    e.preventDefault();
    if(text!==""){
      setCheck(true)
      
    }else{
      setCheck(false)
    }
    props.getDocs(text);
    setDisplay(true)
  }

  const styleDot = check ? "checkbox-comp checked" : "checkbox-comp unchecked";

  let contentVideo = displayVideo ? (
    <div className="carte-comp" onClick={handleDisplay}>
      <div className="haut-carte">
        <div className="text-carte">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
        <div className={styleDot} />
      </div>

      <button className="button-video"
        onClick={handleDisplayVideo}>
        <img className="play-icon" src={playButton} />
        <p>Voir la vidéo explicative</p>
      </button>
    </div>
  ) : (
    <div className="carte-comp" onClick={handleDisplay}>
      <div className="haut-carte">
        <div className="text-carte">
          <h3>Document {props.index}</h3>
          <p>{props.description}</p>
        </div>
        <div className={styleDot} />
      </div>

      <button className="button-video"
        onClick={handleDisplayVideo}>
        <img className="play-icon" src={playButton} />
        <p>Voir la vidéo explicative</p>
      </button>

      <div className="video-container">
        <div className="relative-container">
          <div className="container-title">
            <h2> {props.title}</h2>
          </div>

          <button className="close-button" onClick={handleDisplayVideo}>
            <img src={croix} />
          </button>
          <iframe
            width="1280"
            height="745"
            src={props.srcVideo}
            title="Les solutions de formation - formagora"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );

  let content = display ? (
    contentVideo
  ) : (
    <div className="carte-comp">
      <div className="haut-carte">
        <div className="text-carte">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
        <div className={styleDot} />
      </div>

      <button className="button-video"
        onClick={handleDisplayVideo}>
          <img className="play-icon" src={playButton} />
        <p>Voir la vidéo explicative</p>
      </button>

      <div className="video-container">
        <div className="relative-container">
          <div className="container-title">
            <h2> {props.title}</h2>
            <p>Munissez-vous de votre {props.title} et copiez-collez les parties pertinentes dans le champ ci-dessous.</p>
          </div>

          <button onClick={handleDisplay} className="close-button">
            <img src={croix} />
          </button>
          <div className="container-text">
            
            <textarea
              type="text"
              onChange={inputText}
              value={text}
              placeholder="Copier-coller ici..."
            />
            <p className={`${textErrorCss}`}>{`Ce champ est limité à ${props.lengthText} caractères.`}</p>
          </div>
          <button onClick={validateButton} className="validate-button">Valider</button>
        </div>
      </div>
    </div>
  );

  return content;
}