import copyPaste from "../../../images/design/copyPaste.svg"
import checkCopy from "../../../images/design/checkCopy.svg"
import { useState, useEffect } from "react";

export default function ResultatArea(props) {
  const [content, setContent] = useState(props.text); 
  const [check, setCheck] = useState(false); 

  useEffect(() => {
    setContent(props.text); // Update the content state when props.text changes
  }, [props.text]);
  function inputText(e) {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
    
    setContent(lowerCase);
  }
  const handleClick = () => {
    setCheck(true);

    // Après 2 secondes, réinitialiser la variable à false
    setTimeout(() => {
      setCheck(false);
    }, 5000);
  };

  return <div className="resultat-container">
        <h2>{props.titre}</h2>
        <div className="textarea-container">
            <button onClick={() => {navigator.clipboard.writeText(content); handleClick()}}>
                {check ? <div className="container-copy"> <img src={checkCopy} alt="" />
                <p>texte copié</p> </div >: <div className="container-copy"><img src={copyPaste} alt="" />
                <p>copier le texte</p></div>}
            </button>
            <textarea value={content} onChange={inputText}> {content}</textarea>
        </div>
    </div> ;
  }


  