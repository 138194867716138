
import { useState } from "react";
import VideTextTuto from "./VideoTextTuto";

export default function CardTutoriel(props) {
    const [display, setDisplay] = useState(true);
    let content = display ? 
    <div className="carte-tutoriel" onClick={displayVideo}>
        <img src={props.imgSrc} alt="" />
        <div>
            <h3>{props.title}</h3>
            <p>{props.text}</p>
        </div>
    </div> : 
    <div className="carte-tutoriel" >
        <img src={props.imgSrc} alt="" />
        <div>
            <h3>{props.title}</h3>
            <p>{props.text}</p>
        </div>
        <VideTextTuto srcVideo={props.srcVideo} text={props.longText} title={props.title} handleMethod={displayVideo}/>
    </div>

    function displayVideo(){
        setDisplay(!display);
    }
    
  return (
    content
  );
}