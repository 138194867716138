
import ListeCartes from "../composants/accueilComposants/ListeCartes"

import horloge from "../images/design/horloge.svg"
import star11 from "../images/design/star_11.svg"
import star12 from "../images/design/star_12.svg"
import star21 from "../images/design/star_21.svg"
import star22 from "../images/design/star_22.svg"
import "../css/accueil.css"
import { Link } from "react-router-dom";

export default function Accueil() {

    return <main className="page-accueil">
        <div className="page-width">
            <div className="intro">
                <h4>Formagora vous accompagne !</h4>
            </div>
            <div className="titre-accueil">
                <h1>Créez vos documents de formation <span className="color-text">facilement et rapidement</span></h1>
            </div>
            <div className="text-explicatif">
                <p>{"Bienvenue sur Formabuilder, votre plateforme de création de documents de formation made by formagora. \nCette plateforme a pour but de vous simplifier la vie, formateurs. \nEn vous proposant de générer rapidement vos documents grâce à l’intelligence artificielle. Une formation à venir ? \nDéfilez vers le bas pour découvrir le fonctionnement de Formabuilder ou générez vos documents dès maintenant."}</p>
            </div>
            <Link className="link etoile-link" to="EtapeForm">
                <img className="etoile-design11" src={star11} />
                <img className="etoile-design12" src={star12} />
                <img className="etoile-design21" src={star21} />
                <img className="etoile-design22" src={star22} />
                <button className="boutton-generer">Générer mes documents</button>
            </Link>

            <div className="horloge">
                <img className="clock" src={horloge} />
                <p> 5 minutes</p>
            </div>
            <iframe width="1280" height="745" src="https://www.youtube.com/embed/BJVvNbqts-w?si=RmuzNOt-5F5GPw29" title="Formabuilder - Tutoriel" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div className="aide-text" id="solution">
                <p>Besoin d'un coup de main ?</p>
            </div>
            <div className="container-h2">
                <h2>On sait que ce n'est pas la meilleure partie, alors formagora vous simplifie la vie</h2>
            </div>
            <ListeCartes />
            <Link className="link" to="EtapeForm">
                <button className="boutton-generer generer2">Générer mes documents</button>
            </Link>
        </div>
    </main>
}