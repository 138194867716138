import { useState, useEffect } from "react";
import PageBesoin from "./Formulaires/PageBesoin";
import PageComplementaire from "./Formulaires/PageComplementaire";
import PageContenu from "./Formulaires/PageContenu";
import PageResultat from "./Formulaires/PageResultat";

import { Link } from "react-router-dom";
import ProgressionBar from "../composants/EtapeForm/ProgressionBar"
import flecheEtape from "../images/design/flecheEtape.svg"
import { postClient, testConnection } from "../lib"
import PageConnexion from "./Formulaires/PageConnexion";

export default function EtapeForm() {
  const [etape, setEtape] = useState(0);
  const [verified, setVerified] = useState(true);

  const [data, setData] = useState({ document: [false, false, false, false], titre: "", themes: "", objectifs: "", doc1: "", doc2: "", doc3: "", doc4: "", message: "" })
  const [reponse, setReponse] = useState(["chargement de la réponse", "chargement de la réponse", "chargement de la réponse", "chargement de la réponse", "chargement de la réponse"]);
  /*-------*/
  const [cacheEmail, setCacheEmail] = useState("");
  const [cacheDate, setCacheDate] = useState("");
  const [reponseCo, setReponseCo] = useState([{ email: "", validation: 0 }]);
  /*-----*/
  const SubmitData = async () => {

    const postData = {
      titre: data.titre,
      themes: data.themes,
      objectifs: data.objectifs,
      programme: data.doc1,
      quizz: data.doc2,
      objectif: data.doc3,
      support: data.doc4,
    };

    postClient(postData, setReponse);
    // Assign the entire response object to the state
    console.log(reponse)
    setEtape(3)
  };
  console.log(data)
  /*-----------------*/
  const getAllCacheData = async () => {
    var url = 'https://localhost:300';
    var names = await caches.keys();
    var cacheDataArray = [];

    for (const name of names) {
      const cacheStorage = await caches.open(name);
      const cachedResponse = await cacheStorage.match(url);
      if (cachedResponse) {
        const dataWithDate = await cachedResponse.json();
        cacheDataArray.push(dataWithDate);
      }
    }

    return cacheDataArray;
  };

  const verifyDayPass = async () => {
    const cacheDataArray = await getAllCacheData();
    console.log("cacheDataArray", cacheDataArray);

    const todayCacheDate = new Date().toISOString();
    console.log("todayCacheDate", todayCacheDate);

    if (cacheDataArray && cacheDataArray.length > 0) {
      const { response: cacheEmail, cacheDate } = cacheDataArray[0];
      console.log("cacheDate", cacheDate);

      if (cacheDate !== "" && cacheDate.slice(0, 10) === todayCacheDate.slice(0, 10)) {
        const Data = {
          email: cacheEmail,
        };
        testConnection(Data, setReponseCo);
        return true; // Affiche true car les 9 premiers caractères sont identiques
      } else {

        return false;
      }
    }

    return false;
  };

  useEffect(() => {
    console.log("reponseCo");
    if (reponseCo[0].validation === 1) {
      getVerified(true);
    }
  }, [reponseCo]);

  useEffect(() => {
    console.log("connexion");
    // Fonction à exécuter au chargement de la page
    verifyDayPass().then((result) => {
      // Faire la suite du code ici en fonction du résultat retourné par verifyDayPass()
      console.log("Result of verifyDayPass:", result);
    });

    // Vous pouvez également nettoyer les effets si nécessaire
    // return () => {
    //   // Code de nettoyage ici, si nécessaire
    // };
  }, []);

  /*----------------------*/
  function getDocs(childData) {
    const docs = data.document;
    docs[childData] = !docs[childData];

    setData((prevData) => ({
      ...prevData,
      document: docs
    }));
  }
  function getVerified(childData) {
    setVerified(childData)
  }
  function getTitre(childData) {
    setData((prevData) => ({
      ...prevData,
      titre: childData
    }));
  }
  function getThemes(childData) {
    console.log(childData)
    if (childData.trim() !== "") {
      console.log("in")
      setData((prevData) => ({
        ...prevData,
        themes: prevData.themes.concat(childData)
      }));
    }
  }
  function deleteThemes(textToDelete) {
    const textWithNewlines = '\n' + textToDelete + " ";

    setData(prevData => {
      // Ajoutez le caractère \n devant et après le texte à supprimer

      console.log("prevdata")
      console.log(prevData.themes)

      // Utilisez la méthode replace pour supprimer le texte
      const updatedDataThemes = prevData.themes.replace(textWithNewlines, '');
      console.log(updatedDataThemes)
      // Retourne un nouvel objet avec le texte modifié
      return { ...prevData, themes: updatedDataThemes };
    });



  }
  function getObjectifs(childData) {
    setData((prevData) => ({
      ...prevData,
      objectifs: childData
    }));
  }
  function getDocs1(childData) {
    setData((prevData) => ({
      ...prevData,
      doc1: childData
    }));
  }
  function getDocs2(childData) {
    setData((prevData) => ({
      ...prevData,
      doc2: childData
    }));
  }
  function getDocs3(childData) {
    setData((prevData) => ({
      ...prevData,
      doc3: childData
    }));
  }
  function getDocs4(childData) {
    setData((prevData) => ({
      ...prevData,
      doc4: childData
    }));
  }


  // Initialize content variable using useState
  const [content, setContent] = useState(<PageBesoin handleCallback={setEtape} data={data} etape={etape} dataCallBack={getDocs} />);

  useEffect(() => {
    // Update content when etape changes
    if (etape === 0) {

      setContent(<PageBesoin handleCallback={setEtape} data={data} etape={etape} dataCallBack={getDocs} />);

    } else if (etape === 1) {
      setContent(<PageContenu handleCallback={setEtape} data={data} etape={etape} getTitre={getTitre} getThemes={getThemes} deleteTheme={deleteThemes} getObjectifs={getObjectifs} />);
    } else if (etape === 2) {
      setContent(<PageComplementaire handleCallback={setEtape} data={data} etape={etape} submitData={SubmitData} getDocs1={getDocs1} getDocs2={getDocs2} getDocs3={getDocs3} getDocs4={getDocs4} />);
    } else {
      setContent(<PageResultat handleCallback={setEtape} etape={etape} submitData={SubmitData} data={reponse} documentDisplayed={data?.document || []} />);
    }
  }, [etape, reponse]);


  return verified ? <main className="page-form">
    <div>
      {content}
    </div>
  </main> : <PageConnexion getVerified={getVerified} />;
}