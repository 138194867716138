

import Accueil from "./pages/Accueil";
import EtapeForm from "./pages/EtapesForm";
import PageTutoriel from "./pages/PageTutoriel";
import PageMentions from "./pages/PageMentions";
import PagePolitique from "./pages/PagePolitique"
import NavBar from "./composants/navBar/NavBar";
import { Route, Routes} from "react-router-dom";
import Footer from "./composants/footer/footer"
import ScrollToTop from "./lib/ScrollToTop";




function App() {




    
    return (
        <div className="page">
            <ScrollToTop/>
            <NavBar/>
            <Routes>
                <Route path="/" element={<Accueil/>} />
                <Route path="/EtapeForm" element={<EtapeForm/>} />
                <Route path="/Tutoriels" element={<PageTutoriel/>} />
                <Route path="/Mentions" element={<PageMentions/>} />
                <Route path="/Politique" element={<PagePolitique/>} />
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;


